import { z } from 'zod'

export const responseCodeCollectionSchema = z
  .object({
    generated_response_codes_error: z.record(z.string()),
    generated_response_codes_ok: z.record(z.string()),
  })
  .strict()
export type ResponseCodeCollection = z.infer<typeof responseCodeCollectionSchema>

export const termSchema = z
  .object({
    description: z.string(),
    aliases: z.array(z.string()),
  })
  .strict()
export type Term = z.infer<typeof termSchema>

export const terminologySchema = z.record(termSchema)
export type Terminology = z.infer<typeof terminologySchema>

export const fsPlanSchema = z.object({
  slug: z.string(),
  name: z.string(),
  color: z.string(),
  virus_scanning: z.boolean(),
  ai_features: z.boolean(),
  distributed_video_transcoding: z.boolean(),
  file_size_limit_in_gb: z.number(),
  overage: z.literal(false).or(z.string()),
  price_period: z.string(),
  gb_period: z.string(),
  price: z.string(),
  priority_job_slots: z.number(),
  seats: z.number(),
  support: z.enum(['bronze', 'silver', 'gold', 'diamond']),
  included_gb: z.number(),
  highlighted: z.boolean().optional(),
})

export type FsPlan = z.infer<typeof fsPlanSchema>
export type FsPlans = Record<string, FsPlan>

export const fsPlanCollectionSchema = z.record(fsPlanSchema)
export type FsPlanCollection = z.infer<typeof fsPlanCollectionSchema>

export const boastSchema = z.record(z.string().or(z.number()).or(z.array(z.string())))
export type BoastFacts = z.infer<typeof boastSchema>
